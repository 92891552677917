import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BillDetails from "../components/payment/bill-details"
import GooglePay from "../components/googlePay"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import Container from "@material-ui/core/Container"
import Loading from "../components/common/loading"
import NoPosIntegration from "../components/payment/no-pos-integration"

const StyledBillWrapper = styled.div`
  padding: 30px 0 100px;
`
const StyledButtonsWrapper = styled.div`
  text-align: center;
`
const StyledGooglePayWrapper = styled.div`
  margin: 30px 0;
`
const StyledEmailPayWrapper = styled.div`
  margin: 30px 0;
`
const StyledActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  a {
    margin: 10px 0;
  }
`
const StyledTextField = styled(TextField)`
    display: block;
    width: 100%;
    margin: 30px 0 !important;
`
const PaymentsContactLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/payments-contact" {...props} />
))
const PaymentSuccessLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/payment-success" {...props} />
))
const PaymentFailedLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/payment-failed" {...props} />
))

class PaymentPage extends React.Component {
  state = {
    loading: true,
    posSystemIntegrated: false,
  }

  componentDidMount() {
    this.fetchBillDetails()
  }

  fetchBillDetails() {
    this.setState({ loading: true })
    setTimeout(() => {
      this.setState({ loading: false, posSystemIntegrated: !this.state.posSystemIntegrated })
    }, 1000)
  }

  render() {
    const { loading, posSystemIntegrated } = this.state
    return (
      <Layout>
        <SEO title="Home"/>
        {loading && <Loading/>}
        {!loading && <StyledBillWrapper>
          <StyledActionButtonsWrapper>
            <Button variant="contained" color="secondary" size="large" onClick={this.fetchBillDetails.bind(this)}>
              Wersja druga okna
            </Button>
          </StyledActionButtonsWrapper>
          {posSystemIntegrated && <BillDetails/>}
          {!posSystemIntegrated && <NoPosIntegration/>}

          <StyledEmailPayWrapper>
            <Container maxWidth={"lg"}>
              <StyledTextField
                id="email"
                label="Email"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Container>
          </StyledEmailPayWrapper>
          <StyledButtonsWrapper>
            <StyledGooglePayWrapper>
              <GooglePay/>
            </StyledGooglePayWrapper>
            <StyledActionButtonsWrapper>
              <Button variant="contained" color="primary" size="large" component={PaymentsContactLink}>
                Inne formy płatności
              </Button>
              <Button variant="contained" color="primary" size="large" component={PaymentSuccessLink}>
                Płatność poprawna
              </Button>
              <Button variant="contained" color="primary" size="large" component={PaymentFailedLink}>
                Płatność nieudana
              </Button>
            </StyledActionButtonsWrapper>
          </StyledButtonsWrapper>
        </StyledBillWrapper>}
      </Layout>
    )
  }
}

export default PaymentPage

import React, { Component } from "react"

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
}
const tokenizationSpecification = {
  type: "PAYMENT_GATEWAY",
  parameters: {
    "gateway": "example",
    "gatewayMerchantId": "exampleGatewayMerchantId",
  },
}
const allowedCardNetworks = ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"]
const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"]
const baseCardPaymentMethod = {
  type: "CARD",
  parameters: {
    allowedAuthMethods: allowedCardAuthMethods,
    allowedCardNetworks: allowedCardNetworks,
  },
}
const cardPaymentMethod = Object.assign(
  { tokenizationSpecification: tokenizationSpecification },
  baseCardPaymentMethod,
)

class GooglePay extends Component {
  paymentsClient = null

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const scriptjs = require("scriptjs")
      scriptjs("https://pay.google.com/gp/p/js/pay.js", this.setupGooglePay.bind(this))
    }
  }

  getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, baseRequest)
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod]
    paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo()
    paymentDataRequest.merchantInfo = {
      // @todo a merchant ID is available for a production environment after approval by Google
      // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
      // merchantId: '01234567890123456789',
      merchantName: "Example Merchant",
    }
    return paymentDataRequest
  }

  setupGooglePay() {
    const paymentsClient = this.getGooglePaymentsClient()
    const isReadyToPayRequest = Object.assign({}, baseRequest)
    isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod]
    paymentsClient.isReadyToPay(isReadyToPayRequest).then((response) => {
      if (response.result) {
        const button = paymentsClient.createButton({
          onClick: () => this.onGooglePaymentButtonClicked(),
        })
        document.getElementById("google-pay-wrapper").appendChild(button)
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  getGoogleTransactionInfo() {
    return {
      currencyCode: "PLN",
      totalPriceStatus: "FINAL",
      // set to cart total
      totalPrice: "1.00",
    }
  }

  getGooglePaymentsClient() {
    if (this.paymentsClient === null) {
      this.paymentsClient = new window.google.payments.api.PaymentsClient({ environment: "TEST" })
    }
    return this.paymentsClient
  }

  processPayment(paymentData) {
    // show returned data in developer console for debugging
    console.log(paymentData)
    // @todo pass payment data response to your gateway to process payment
  }

  onGooglePaymentButtonClicked() {
    const paymentDataRequest = this.getGooglePaymentDataRequest()
    const processPayment = this.processPayment.bind(this)
    paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo()

    const paymentsClient = this.getGooglePaymentsClient()
    paymentsClient.loadPaymentData(paymentDataRequest)
      .then(function(paymentData) {
        // handle the response
        processPayment(paymentData)
      })
      .catch(function(err) {
        // show error in developer console for debugging
        console.error(err)
      })
  }

  render() {
    return (
      <div id="google-pay-wrapper"/>
    )
  }
}

export default GooglePay
import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import styled from "styled-components"

const StyledLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
  z-index: 9999999;
`

const Loading = () => {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    function tick() {
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1))
    }

    const timer = setInterval(tick, 20)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <StyledLoadingWrapper>
      <CircularProgress variant="determinate" value={progress}/>
    </StyledLoadingWrapper>
  )
}

export default Loading
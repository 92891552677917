import React, { Component } from "react"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import ChooseTip from "./choose-tip"

const dishes = [{
  name: "Danie dnia",
  netto: 100,
}, {
  name: "Danie dnia",
  netto: 231,
}, {
  name: "Danie dnia",
  netto: 13,
}, {
  name: "Danie dnia",
  netto: 37,
}, {
  name: "Danie dnia",
  netto: 61,
}]

const StyledPaper = styled(Paper)`
  padding: 0 10px;
`

const getTotalPrice = (dishes) => dishes.reduce((total, dish) => (dish.netto * 1.23) + total, 0)

class BillDetails extends Component {

  render() {
    const totalPrice = getTotalPrice(dishes)

    return (
      <Container maxWidth={"lg"}>
        <p><b>Szczegóły rachunku</b></p>
        <StyledPaper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nazwa</TableCell>
                <TableCell align="right">Netto</TableCell>
                <TableCell align="right">Brutto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dishes.map((row, key) => <TableRow key={key}>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell align="right">{row.netto.toFixed(2)}</TableCell>
                <TableCell align="right">{(row.netto * 1.23).toFixed(2)}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </StyledPaper>
        <p>Twój rachunek aktualnie wynosi: <b>{totalPrice.toFixed(2)} zł.</b></p>
        <ChooseTip/>
      </Container>
    )
  }
}

export default BillDetails
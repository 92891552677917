import React from "react"
import styled from "styled-components"

const StyledTipsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledTip = styled.div`
  padding: 40px 0;
  width: 30%;
  border: 3px solid #3f51b5;
  background-color: ${({ active }) => active ? "#3f51b5" : "#fff"};
  color: ${({ active }) => active ? "#fff" : "#000"};
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  border-radius: 100%;
  transition: all 0.4s ease;
`

export default () => {
  const [value, setValue] = React.useState(null)

  function handleChange(newValue) {
    setValue(value === newValue ? null : newValue)
  }

  return (
    <>
      <p>Wielkośc napiwku:</p>
      <StyledTipsWrapper>
        <StyledTip onClick={() => handleChange("10%")} active={value === "10%"}>10%</StyledTip>
        <StyledTip onClick={() => handleChange("5%")} active={value === "5%"}>5%</StyledTip>
        <StyledTip onClick={() => handleChange("10zł")} active={value === "10zł"}>10zł</StyledTip>
      </StyledTipsWrapper>
    </>
  )
}
import React, { Component } from "react"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import ChooseTip from "./choose-tip"
import TextField from "@material-ui/core/TextField"

const StyledTextField = styled(TextField)`
    display: block;
    width: 100%;
    margin-bottom: 30px !important;
`

class NoPosIntegration extends Component {

  render() {

    return (
      <Container maxWidth={"lg"}>
        <p><b>Wprowadź kwotę, która znajduje się na twoim rachunku</b></p>
        <StyledTextField
          id="price"
          label="Kwota"
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <ChooseTip/>
      </Container>
    )
  }
}

export default NoPosIntegration